<template>
  <div>
    <div :style="{ background: bgColor, color: textColor }">
      <div style="float: left;width: 100%">
        <span @click="$router.push('/home')" style="cursor: pointer">
          <img style="padding: 12px; width: 25px; height: 25px;float: left;" src="../../../assets/logo.png" />
          <span style="float: left; line-height: 50px; font-size: 20px; padding-left: 5px;">
            新冠肺炎防控管理信息平台
          </span>
        </span>
        <div @click="isCollapseShow"
          style="margin: 0 20px;height: 50px;float: left;display: flex;align-items: center;font-size: 26px;cursor: pointer">
          <i class="el-icon-s-fold" v-if="!isCol"></i>
          <i class="el-icon-s-unfold" v-else></i>
        </div>
        <span v-if="haveRouter" style="float: left; margin-left: 40px">
          <span v-for="(r, i) in routerList" :key="i">
            <span v-if="r.meta.isShow">
              <span @click="routerChange(r)" class="menu_item1">
                <!--                {{r.name}}-->
              </span>
            </span>
          </span>
        </span>


        <span style=" float: right;">
          <span style="margin-right:20px;">
            <el-dropdown @command="handleCommand2">
              <span class="el-dropdown-link" style="color:white;cursor: pointer;font-size:15px;">
                {{ org.orgName }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in userOrgList" :key="item.orgId" :command="item.orgId">{{ item.orgName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>

          <span style="line-height: 50px; font-size: 18px; margin-right: -15px;">
            {{ userDoctor ? userDoctor.nickName : "" }}
          </span>
          <span style="padding: 5px 40px 0px 20px; float: right; ">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <el-avatar style="cursor: pointer" src="../../../assets/img/tx.png"></el-avatar>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="info" divided>基本信息</el-dropdown-item>
                <el-dropdown-item command="setPassword" divided>修改密码</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </span>


      </div>
      <div style="clear: both"></div>
    </div>


    <!-- 修改个人基本信息 -->
    <el-dialog title="编辑个人基本信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px"
      append-to-body>
      <el-form :model="formInfo" ref="addForm" label-width="130px" label-position="right" style="width:90%;">

        <el-form-item label="真实姓名：" prop="nickName" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
          <el-input v-model.trim="formInfo.nickName" placeholder="请输入" maxlength="10" />
        </el-form-item>

        <el-form-item label="用户名：" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
          <el-input v-model.trim="formInfo.name" :disabled="formInfo.id != null" maxlength="15" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="手机号码：" prop="linkPhone" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
          <el-input v-model.trim="formInfo.linkPhone" maxlength="11" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="身份证号：" prop="cardNo" :rules="{ required: false, message: '不能为空', trigger: 'blur' }">
          <el-input maxlength="18" v-model.trim="formInfo.cardNo" placeholder="请输入" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>


    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible2" :close-on-click-modal="false" width="600px"
      append-to-body status-icon>
      <el-form :model="formInfo2" ref="addForm2" label-width="130px" label-position="right" style="width:90%;">

        <el-form-item label="原密码：" prop="oldPwd" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
          <el-input placeholder="请输入" v-model.trim="formInfo2.oldPwd" type="password" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="新密码：" prop="pwd" :rules="{ required: true, trigger: 'blur', validator: validatePass }">
          <el-input placeholder="请输入" v-model.trim="formInfo2.pwd" type="password" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="确认密码：" prop="checkPass"
          :rules="{ required: true, trigger: 'blur', validator: validatePass2 }">
          <el-input placeholder="请输入" v-model.trim="formInfo2.checkPass" type="password" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm2')">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {
  updateUserInfo,
  updatePwd,
  getUserOrgList,
  changeUserOrg
} from "@/api/SystemManagement/index.js";

export default {
  name: "index",
  data() {
    return {
      communityHospitalList: [],
      org: null,
      activeTextColor: "#fff",
      defaultActive: "/index",
      isCol: false,
      userDoctor: JSON.parse(localStorage.getItem("userdoctor")),

      dialogFormVisible: false,
      formInfo: {},
      dialogFormVisible2: false,
      formInfo2: {},

      userOrgList: []
    };
  },
  props: {
    avatarUrl: {
      type: String,
      default: "static/images/doctor.png"
    },
    haveRouter: {
      type: Boolean
    },
    routerList: {
      type: Array
    },
    bgColor: {
      type: String,
      default: "#2d77ee"
    },
    hoverBgColor: {
      type: String,
      default: "#245FBE"
    },
    textColor: {
      type: String,
      default: "#fff"
    },
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.isCol = this.isCollapse;
    if (this.$store.state.user.orgInfo) {
      this.org = this.$store.state.user.orgInfo;
    } else {
      this.$store.dispatch("setOrgInfo", "");
    }
    // this.getCommunityHospitals()
  },
  mounted() {
    this.defaultActive = this.$route.fullPath;
    if (this.routerList) {
      for (let i = 0; i < this.routerList.length; i++) {
        if (this.routerList[i].meta.isShow) {
          this.routerChange(this.routerList[i]);
          break;
        }
      }
      // this.routerList.forEach(router => {
      //   if (this.$route.meta.code && this.$route.meta.code.substr(0, 3) === router.meta.code) {
      //     this.routerChange(router);
      //   }
      // });
    }

    this.getUserOrgListFun();

  },
  methods: {
    fun() {
      location.reload();
    },
    getUserOrgListFun() {
      getUserOrgList().then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.userOrgList = data;
        }
      });
    },
    // orgId
    handleCommand2(id) {
      // console.log(id, this.userDoctor);
      if (id != this.org.id) {
        this.changeUserOrgFun(id);
      }
    },
    changeUserOrgFun(id) {
      let params = { orgId: id };

      //退出到login页面，再调取接口-----------------------------
      this.$router.push({ name: "login", params: { type: 'changeUserOrg', orgId: id } });

    },


    isCollapseShow() {
      this.isCol = !this.isCol;
      this.$emit("update:isCollapse", this.isCol);
    },
    handleCommand(command) {
      switch (command) {
        case "info":
          this.info();
          break;
        case "setPassword":
          this.setPassword();
          break;
        case "logout":
          this.logout();
          break;
      }
    },

    info() {
      this.dialogFormVisible = true;
      this.formInfo = Object.assign({}, this.userDoctor);
    },

    setPassword() {
      this.dialogFormVisible2 = true;
      this.formInfo2 = {
        oldPwd: "",
        pwd: "",
        checkPass: ""
      };
    },

    logout() {
      this.$router.push({ path: "/login" }).then(() => {
        this.$store.dispatch("LoginOut");
      });
    },

    routerChange(r) {
      this.$emit("routerChange", r.children);
    },

    //表单验证
    validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.formInfo2.checkPass !== '') {
          this.$refs.addForm2.validateField('checkPass');
        }
        callback();
      }
    },
    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formInfo2.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },


    //表单保存
    dialogSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == 'addForm') {
            this.submitFormFun();
          } else if (formName == 'addForm2') {
            this.submitFormFun2();
          }
        } else {
          return false;
        }
      });
    },
    submitFormFun() {
      let params = Object.assign({}, this.formInfo);
      // console.log("🚀 ~ file: mechanismManage.vue ~ line 250 ~ submitFormFun ~ params", params)
      updateUserInfo(params).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '修改成功!'
          });

        }
      })
    },
    submitFormFun2() {
      let params = this.formInfo2;
      // console.log("🚀 ~ file: mechanismManage.vue ~ line 268 ~ submitFormFun ~ params", params)
      updatePwd(params).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.dialogFormVisible2 = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
$bgcolor: #545c64;

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  min-height: 400px;
}

.el-menu-item i {
  color: #fff;
}

.menu_item {
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  cursor: pointer;
  font-size: 14px;
  padding: 0 23px;
  text-align: center;
}

.menu_item1 {
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  cursor: pointer;
  line-height: 50px;
  font-size: 16px;
  padding-left: 5px;
  margin: 0 5px;
  color: #e6e6e6;
}

.menu_item1:hover {
  color: #ffffff;
}

.selected {
  /*border-bottom: 2px solid #2d77ee !important;*/
  border-bottom: 2px solid #fff !important;
}
</style>
